import { useCallback } from "react";
import CaptionFontSizeSelector from "./CaptionFontSizeSelector";
import { useCaptionStreamContext, CaptionStreams } from "../context/CaptionStreamContext";

export default function Footer() {
    const {captionStream, setCaptionStream} = useCaptionStreamContext();

    const handleCaptionStreamChange = useCallback(stream => (e) => {
        e.preventDefault();
        setCaptionStream(stream);
    }, [setCaptionStream]);

    return (
        <footer>
            <CaptionFontSizeSelector />
            <nav aria-label="Footer Navigation">
                <a 
                    href="#live-captions" 
                    onClick={handleCaptionStreamChange(CaptionStreams.LIVE)} 
                    className={captionStream === CaptionStreams.LIVE ? 'active' : null}>
                    Live Captions
                </a>
                <a 
                    href="#test-captions" 
                    onClick={handleCaptionStreamChange(CaptionStreams.TEST)} 
                    className={captionStream === CaptionStreams.TEST ? 'active' : null}>
                    Test Captions
                </a>
            </nav>
        </footer>
    );
}