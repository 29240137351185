import ZoomMtgEmbedded from "@zoom/meetingsdk/embedded";

const {
    REACT_APP_ZOOM_AUTH_ENDPOINT,
    REACT_APP_ZOOM_SDK_KEY,
    REACT_APP_ZOOM_MEETING_NUMBER,
    REACT_APP_ZOOM_PASSCODE,
    REACT_APP_ZOOM_MEETING_USERNAME,
} = process.env;

export const Roles = Object.freeze({
    participant: 0,
    host: 1,
});

export async function getSignature({
    role,
}) {
    try {
        const res = await fetch(REACT_APP_ZOOM_AUTH_ENDPOINT, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                meetingNumber: REACT_APP_ZOOM_MEETING_NUMBER,
                role,
            }),
        });
        const data = await res.json();
        return data.signature;
    } catch (e) {
        // TODO: actual error handling
        console.log(e);
    }
}

export async function startMeeting({
    signature, 
    meetingSDKElementRef, 
}) {
    console.log("trying to join");
    try {
        const client = ZoomMtgEmbedded.createClient();
        const width = window.innerWidth;
        const height = window.innerHeight;
        await client.init({
            zoomAppRoot: meetingSDKElementRef.current,
            language: "en-US",
            patchJsMedia: true,
            leaveOnPageUnload: true,
            customize: {
                video: {
                    isResizable: true,
                    viewSizes: {
                        default: {
                            width: width / 2,
                            height: height,
                        },
                    }
                }
            }
        });
        const payload = {
            signature,
            sdkKey: REACT_APP_ZOOM_SDK_KEY,
            meetingNumber: REACT_APP_ZOOM_MEETING_NUMBER,
            password: REACT_APP_ZOOM_PASSCODE,
            userName: REACT_APP_ZOOM_MEETING_USERNAME,
        };
        console.log({payload});
        await client.join(payload);
        console.log("joined successfully");
    } catch (error) {
        // TODO: actual error handling
        console.log(error);
    }
}