import { createContext, useContext, useState, useMemo } from "react";

export const fontSizes = [8, 16, 28, 36, 48, 66, 72];

export const CaptionStreams = Object.freeze({
    LIVE: 'a11ynyc',
    TEST: 'IHaveADream'
});

export const CaptionStreamContext = createContext({
    fontSize: 72,
    fontSizes,
    setFontSize: () => {},
    captionStream: CaptionStreams.TEST,
    setCaptionStream: () => {},
});


export function CaptionStreamContextProvider({
    children,
}) {
    const [fontSize, setFontSize] = useState(72);
    const [captionStream, setCaptionStream] = useState(CaptionStreams.TEST);

    const value = useMemo(() => ({
        fontSize,
        setFontSize,
        fontSizes,
        captionStream,
        setCaptionStream
    }), [fontSize, setFontSize, captionStream, setCaptionStream]);

    return (
        <CaptionStreamContext.Provider value={value}>
            {children}
        </CaptionStreamContext.Provider>
    );
}

export function useCaptionStreamContext() {
    return useContext(CaptionStreamContext);
}