import './App.css';
import Footer from './components/Footer';
import Header from './components/Header';
import LiveCaptionSection from './components/LiveCaptionSection';
import ZoomSection from './components/ZoomSection';
import { CaptionStreamContextProvider } from './context/CaptionStreamContext';

function App() {
  return (
    <div id="App">
      <Header />
      <CaptionStreamContextProvider>
        <main>
          <LiveCaptionSection />
          <ZoomSection />
        </main>
        <Footer />
      </CaptionStreamContextProvider>
    </div>
  );
}

export default App;
