import { useCallback } from "react";
import { useCaptionStreamContext } from "../context/CaptionStreamContext";

export default function CaptionFontSizeSelector() {
    const {fontSize, setFontSize, fontSizes} = useCaptionStreamContext();

    const handleChange = useCallback(({target}) => {
        setFontSize(target.value);
    }, [setFontSize]);

    return (
        <label htmlFor="caption-font-size-selector">
            Caption Font Size: 
            <select id="caption-font-size-selector" onChange={handleChange} value={fontSize}>
                {fontSizes.map(size => (
                    <option value={size} label={size} key={size} />
                ))}
            </select>
        </label>
    );
}