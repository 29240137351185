import {getSignature, startMeeting, Roles} from '../services/ZoomAPI';
import { useCallback, useRef } from "react";

export default function ZoomSection() {
    const meetingSDKElementRef = useRef();

    const handleClick = useCallback(async () => {
        const signature = await getSignature({role: Roles.participant});
        await startMeeting({
            signature,
            username: "Brian Ogilvie",
            meetingSDKElementRef,
        });
    }, []);

    return (
        <section aria-label="ASL" id="ASL">
            <div ref={meetingSDKElementRef} id="asl-zoom-meeting" />
            <button onClick={handleClick}>Click to Join ASL Meeting</button>
        </section>
    );
}
