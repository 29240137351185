import { useCaptionStreamContext } from "../context/CaptionStreamContext";

export default function LiveCaptionSection() {
	const {fontSize, captionStream} = useCaptionStreamContext();
    const src = `https://www.streamtext.net/player/?event=${captionStream}&fs=${fontSize}&bgc=000000&fgc=ffffff&ff=Verdana&header=false&footer=false`
    return (
        <section aria-label="Captions">
			<iframe
				src={src}
				title="Captions"
				loading="lazy"
			></iframe>
		</section>
    );
}